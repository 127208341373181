<template>
    <div>
        <b-button @click="addInventory" variant="primary">
            <feather-icon icon="PlusIcon" /> Add
        </b-button>
    </div>
</template>

<script>

import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab
} from 'bootstrap-vue'
import store from '@/store'

import InventoriesProvider from '@/providers/Inventories'
const InventoriesResource = new InventoriesProvider()

export default {
    name: "ProvisionalIncome",
    components: {
        BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
        BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
        BBadge, BCardSubTitle, BCardTitle, BTabs, BTab
    },
    data() {
        return {
            
        };
    },
    methods: {
      async addInventory() {

        const payload = {
            docto_Id_Id: -1,
            almacen_Id: store.getters['auth/getUser'].almacen_Id,
            concepto_In_Id: 0,
            sucursal_Id: store.getters['auth/getBranch'].sucursal_Id,
            folio: "string",
            naturaleza_Concepto: "string",
            fecha: "2024-08-08T18:58:35.457Z",
            almacen_Destino_Id: 0,
            centro_Costo_Id: 0,
            cancelado: "string",
            aplicado: "string",
            descripcion: "string",
            cuenta_Concepto: "string",
            forma_Emitida: "string",
            contabilizado: "string",
            sistema_Origen: "string",
            usuario_Creador: "string",
            fecha_Hora_Creacion: "2024-08-08T18:58:35.457Z",
            usuario_Aut_Creacion: "string",
            usuario_Ult_Modif: "string",
            fecha_Hora_Ult_Modif: "2024-08-08T18:58:35.457Z",
            usuario_Aut_Modif: "string",
            usuario_Cancelacion: "string",
            fecha_Hora_Cancelacion: "2024-08-08T18:58:35.457Z",
            usuario_Aut_Cancelacion: "string"
        }

        const { data } = await InventoryResource.create(payload)
        console.log(data)
      },
    },
    setup() {
        
    },
}
</script>